<template>
	<div>
		<a-row type="flex" :gutter="24">
			<a-col :span="24" :xxl="6" :xl="8" :lg="12" :sm="12" class="mb-24" v-for="(item, index) in memberData.list"
				:key="index">
				<!-- :key="'card_' + item.id" -->
				<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '20px 16px' }">
					<div class="body_box">
						<div class="">
							<span class="text-primary text-sm font-bold">有效期1{{ item.type_text }}</span>
						</div>
						<div class="flex-csb">
							<h3>{{ item.title }}</h3>
							<span class="text-primary text-line font-bold">原价：￥{{ item.original_price }}</span>
						</div>
						<p class="text-sm text-gray-7">{{ item.desc }}</p>
						<a-button type="primary" shape="round" block
							style="height: 50px; font-size: 14px; margin-bottom: 30px;background: #dab2f9; border: 1px solid #dab2f9" @click="shopNow(item)">立即购买￥{{
								item.price }}</a-button>
						<div>
							<div class="flex-csb mb-10" v-for="content in item.content" :key="'content_' + content.id">
								<div>
									<a-icon type="check-circle" theme="filled" :style="{ color: '#1890FF' }" />
									<span class="ml-5 text-dark">{{ content.text }}</span>
								</div>
								<div class="text-primary">{{ content.limit }}</div>
							</div>
						</div>
					</div>
				</a-card>
			</a-col>
		</a-row>
		<PayModal :showModal="showModal" payType="member" :prepayData="prepay" @hideModal="showModal = false"></PayModal>
	</div>
</template>

<script>
import PayModal from "./PayModal.vue"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
	props: {},
	components: {
		PayModal
	},
	data() {
		return {
			memberData: [],
			prepay: {},
			showModal: false
		}
	},
	watch: {},
	computed: {},
	filters: {},
	created() {
		this.getMemberList()
	},
	mounted() { },
	methods: {
		// 立即购买
		shopNow(item) {
			this.prepay = {
				id: item.id,
				title: item.title,
				price: item.price
			}
			this.showModal = true
		},
		// 获取会员列表
		getMemberList() {
			this.$http("recharge.memberList").then(res => {
				if (res.code === 1) {
					res.data.list.map(item => {
						if (item.content) {
							let id = 0
							let arr = []
							for (let key in item.content) {
								id++
								arr.push({
									id,
									text: key,
									limit: item.content[key]
								})
							}
							item.content = arr
						}
					})
					this.memberData = res.data
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.prepay_modal {
	padding: 20px 0;

	.line {
		display: flex;
		margin-bottom: 30px;

		.label {}

		.value {
			display: flex;
			align-items: center;

			.way {
				border: 1px solid #ddd;
				padding: 10px 20px;
				border-radius: 10px;
				display: flex;
				align-items: center;
				margin-right: 15px;
				cursor: pointer;

				&.active {
					border-color: #23b571;
				}

				span {
					margin-left: 10px;
				}
			}
		}
	}

	.button {
		margin-top: 50px;
		display: flex;
		justify-content: center;
	}
}

.qrcode_modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 80px 0;

	.qrcode {
		width: 200px;
		height: 200px;
	}
}
</style>
