<template>
	<div>
		<a-row type="flex" :gutter="24">
			<a-col
				:span="24"
				:xxl="6"
				:xl="8"
				:lg="12"
				:sm="12"
				class="mb-24"
				v-for="(item, index) in dealerData"
				:key="'card_' + item.id">
				<a-card
					:bordered="false"
					class="header-solid h-full test"
					:style="{
						background: index == 2 || index == 3 ? 'linear-gradient(#ffedb7 0%,#fff1c5 20%,#fef0c2 100%)' : '',
					}"
					:bodyStyle="{ padding: '24px 16px' }">
					<div class="body_box">
						<div style="text-align: center">
							<!-- class="text-primary text-sm font-bold" -->
							<span class="text-primary font-bold" style="font-size: 30px; text-align: center; color: #333">{{
								item.name
							}}</span>
							<p class="font-bold text-center mt-5 mb-5">
								<span style="font-size: 16px">￥</span>
								<span style="font-size: 30px" class="text-line"> {{ item.original_price }}</span>
							</p>
							<a-button type="primary" shape="round" block style="height: 50px; font-size: 14px;background: #dab2f9; border: 1px solid #dab2f9" @click="shopNow(item)"
								>服务费：￥{{ item.price }}</a-button
							>
							<!-- :style="{background: index == 2 || index == 3 ? 'linear-gradient(#9c7b42 0%,#a98546 20%,#ad8847 100%)' : '',}" -->
							<!-- <span class="text-primary text-sm font-bold">有效期：{{ item.expire_text }}</span> -->
						</div>
						<div
							class="subscript"
							:style="{
								background: index == 2 || index == 3 ? 'linear-gradient(#9c7b42 0%,#a98546 20%,#ad8847 100%)' : '',
							}">
							{{ item.expire_text }}
						</div>
						<div class="flex-csb mt-5 mb-10">
							<!-- <h5>消费返佣：{{ Number(item.direct) }}%</h5>
							<h5>打赏返佣：{{ Number(item.indirect) }}%</h5> -->
							<!-- <h6>消费返佣：90% <span style="font-size: 10px;">（直推{{ Number(item.direct) }}% + {{ Number(item.indirect) }}%）</span> </h6> -->
						</div>
						<!-- <h6>打赏返佣：90%</h6> -->
						<a-divider />
						<div class="text-gray-7 mb-10 mt-30">
							<div class="text-sm mb-5 fs14" v-for="content in item.content" :key="'content_' + content.id">
								<p>
									<a-icon type="check-circle" theme="filled" class="mr-5" :style="{ color: '#1890FF' }" />{{
										content.text.substring(0, content.text.indexOf("："))}}
								</p>
								<!-- {{content.text.substr(-content.text.indexOf("："), 4)}} -->
								<!-- <p>{{ content.text }}</p> -->
								<p>
									{{ content.text.indexOf("：") == -1 ? "" : content.text.substr(content.text.indexOf("：") + 1, 9) }}
								</p>
							</div>
						</div>
					</div>
				</a-card>
			</a-col>
		</a-row>
		<PayModal :showModal="showModal" payType="dealer" :prepayData="prepay" @hideModal="showModal = false"></PayModal>
	</div>
</template>

<script>
	import PayModal from "./PayModal.vue";
	export default {
		components: {
			PayModal,
		},
		data() {
			return {
				dealerData: [],
				prepay: {},
				showModal: false,
			};
		},
		created() {
			this.getDealerList();
		},
		methods: {
			// 立即购买
			shopNow(item) {
				console.log(item, "item");
				// return
				this.prepay = {
					id: item.id,
					title: item.name + " " + item.expire_text,
					price: item.price,
				};
				this.showModal = true;
			},
			// 获取经销商列表
			getDealerList() {
				this.$http("recharge.dealerList").then((res) => {
					if (res.code === 1) {
						if (res.data.list && res.data.list.length) {
							res.data.list.map((item) => {
								if (item.content) {
									let id = 0;
									let arr = [];
									for (let key in item.content) {
										id++;
										arr.push({
											id,
											text: item.content[key],
										});
									}
									item.content = arr;
								}
							});
							this.dealerData = res.data.list;
						}
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.test {
		// background: pink;
	}
	.test:hover {
		transform: scale(1.03, 1.03);
		border: 1px solid #1890ff;
	}
	.back {
		background: #fef0c2;
	}
	.fs14 {
		font-size: 16px;
		color: #142738;
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
	}
	.subscript {
		width: 60px;
		height: 25px;
		background: #1890ff;
		color: #fff;
		text-align: center;
		line-height: 25px;
		position: absolute;
		top: 0px;
		right: 0px;
		border-radius: 0 10px 0 10px;
	}
</style>
