<template>
	<div>
		<a-modal v-model="prepayModal" title="立即购买" :footer="null" :maskClosable="false" :centered="true">
			<div class="prepay_modal">
				<div class="line">
					<div class="label">购买套餐：</div>
					<div class="value">{{ prepay.title }}</div>
				</div>
				<div class="line">
					<div class="label">支付金额：</div>
					<div class="value">{{ prepay.price }}</div>
				</div>
				<div class="line">
					<div class="label" style="margin: auto 0;">支付方式：</div>
					<div class="value">

						<div class="way" :class="{ active: payment === 'alipay' }" @click="payment = 'alipay'">
							<a-icon type="alipay-circle" theme="filled" :style="{ fontSize: '20px', color: '#019FE8' }" />
							<span>支付宝(推荐)</span>
						</div>
						<div class="way" :class="{ active: payment === 'wechat' }" @click="payment = 'wechat'">
							<a-icon type="wechat" theme="filled" :style="{ fontSize: '23px', color: '#09BB07' }" />
							<span>微信支付</span>
						</div>
						<div class="way" :class="{ active: payment === 'wallet' }" @click="payment = 'wallet'">
							<svg t="1693204840760" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="4259" id="mx_n_1693204840761" width="20"
								height="20">
								<path
									d="M512.512 47.616c-254.464 0-460.8 206.336-460.8 460.8s206.336 460.8 460.8 460.8 460.8-206.336 460.8-460.8-206.336-460.8-460.8-460.8z m144.896 445.952v46.592h-114.176v64.512h114.176v47.616h-114.176v93.696H484.352v-93.696H365.568v-47.616h118.272v-64.512H365.568v-46.592h100.864l-122.88-211.456H409.6c57.856 104.96 92.672 172.032 104.96 200.192h1.024c4.096-11.776 15.872-34.816 34.304-70.144l70.656-130.048h62.464l-124.416 211.968h98.816z"
									fill="#FE9C01" p-id="4260"></path>
							</svg>
							<span>账户余额</span>
						</div>
					</div>
				</div>
				<div class="line" v-show="checked">
					<div class="label"></div>
					<div class="value"><a-checkbox @change="onChange">
							同意<a href=""><router-link to="/identification"
									target="_blank">《角色协议》</router-link></a>视为所认证角色无侵权纠纷
						</a-checkbox></div>
				</div>
				<div class="button">
					<a-button type="primary" :disabled="disabled"
						style="width: 150px; background: #23b571; border-color: #23b571"
						@click="createOrder">立即支付</a-button>
				</div>
			</div>
		</a-modal>

		<a-modal v-model="payModal" :title="payment | paymentText" :footer="null" :maskClosable="false" :centered="true">
			<div class="qrcode_modal">
				<div class="qrcode">
					<div ref="qrCodeUrl"></div>
				</div>
				<p class="text-gray-7 mt-20 mb-0">请使用手机{{ payment | paymentText }}</p>
			</div>
		</a-modal>
	</div>
</template>

<script>
import QRCode from "qrcodejs2"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import { boolean } from 'mathjs'
export default {
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		prepayData: {
			type: Object,
			default: () => { }
		},
		payType: {
			type: String,
			default: ""
		},
		// checked: {
		// 	type: Boolean,
		// 	default: false
		// }
	},
	data() {
		return {
			payModal: false,
			prepayModal: false,
			payment: "alipay",
			pay: {
				order: "",
				qrcode: "",
				timer: null
			},
			prepay: {
				id: "",
				title: "",
				price: ""
			},
			// 认证协议
			checked: false,
			isChceked: false,
			disabled: '',
			UserInfo: {}
		}
	},
	watch: {
		prepayModal(newValue, oldValue) {
			if (!newValue) {
				this.$emit("hideModal", false)
				this.payModal = false
			}
		},
		payModal(newValue, oldValue) {
			if (!newValue) {
				clearInterval(this.pay.timer)
				this.pay = {
					order: "",
					qrcode: "",
					timer: null
				}
			}
		},
		showModal(newValue, oldValue) {
			this.prepayModal = newValue
		},
		UserInfo(newValue, oldValue) {
			this.UserInfo = newValue
		},
		prepayData: {
			deep: true,
			handler(newValue, oldValue) {
				this.prepay = newValue
			}
		}
	},
	filters: {
		paymentText(value) {
			if (value === "wechat") {
				return "微信扫一扫支付"
			} else if (value === "alipay") {
				return "支付宝扫一扫支付"
			}
		}
	},
	created() {
		if (this.payType == 'dealer') {
			this.checked = true
			// this.disabled = true
		} else {
			this.checked = false
			this.disabled = false
		}
		this.payType == 'dealer' ? this.checked = true : this.checked = false
		// if (this.checked = true || this.payType == 'dealer') {
		// 	this.disabled = false
		// }
		this.UserInfo = this.$store.state.user.userInfo


	},
	mounted() { },
	methods: {
		...mapActions("user", ["getUserInfo"]),
		// 查询支付状态
		queryPayStatus() {
			this.$http("recharge.queryPayStatus", {
				payment: this.payment,
				orderid: this.pay.order
			})
				.then(res => {
					if (res.code === 1) {
						if (res.data && res.data.status && res.data.status === "SUCCESS") {
							this.getUserInfo()
							this.$message.success("支付成功！")
							this.prepayModal = false
						}
					} else {
						this.$message.error("支付失败！")
						this.payModal = false
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		// 生成二维码
		creatQrCode() {
			// 重复创建二维码会，之前的二维码还在，新创建的会被接在下面
			// 此行代码是为了解决以上问题 或者 刷新二维码的时候生成多个二维码的问题
			if (this.$refs.qrCodeUrl) this.$refs.qrCodeUrl.innerHTML = ""
			new QRCode(this.$refs.qrCodeUrl, {
				text: this.pay.qrcode, // 需要转换为二维码的内容
				width: 200,
				height: 200,
				correctLevel: QRCode.CorrectLevel.H
			})
			this.pay.timer = setInterval(() => {
				this.queryPayStatus()
			}, 1000)
		},
		// 生成订单
		createOrder() {
			if (!this.payType) {
				this.$message.error("购买失败！")
				return
			}

			if (this.payType === "integral") {
				console.log("积分充值")
				const obj = {
					usable_id: this.prepay.id,
					total_fee: this.prepay.price,
					platform: "Web"
				}
				this.$http("recharge.createIntegralOrder", obj)
					.then(res => {
						if (res.code === 1) {
							this.pay.order = res.data.order_sn
							this.postPay(res.data.order_sn)
						}
					})
					.catch(err => {
						console.log(err, "err")
					})
			} else if (this.payType === "member") {
				console.log("会员充值")
				const obj = {
					vip_id: this.prepay.id,
					total_fee: this.prepay.price,
					platform: "Web"
				}
				this.$http("recharge.createMemberOrder", obj)
					.then(res => {
						if (res.code === 1) {
							this.pay.order = res.data.order_sn
							this.postPay(res.data.order_sn)
						}
					})
					.catch(err => {
						console.log(err, "err")
					})
			} else if (this.payType === "dealer") {

				console.log("经销商充值")
				const obj = {
					reseller_id: this.prepay.id,
					total_fee: this.prepay.price,
					platform: "Web"
				}
				this.$http("recharge.createDealerOrder", obj)
					.then(res => {
						if (res.code === 1) {
							this.pay.order = res.data.order_sn
							this.postPay(res.data.order_sn)
						}
					})
					.catch(err => {
						console.log(err, "err")
					})
			}
		},
		// 支付
		postPay(sn) {
			const obj = {
				order_sn: sn,
				payment: this.payment,
				platform: "Web"
			}

			this.$http("recharge.pay", obj)
				.then(res => {
					if (res.code === 1) {
						this.pay.qrcode = res.data.pay_data
						if (obj.payment != 'wallet') {
							this.payModal = true
							const timer = setTimeout(() => {
								this.creatQrCode()
								clearTimeout(timer)
							}, 1000)
						} else {
							this.$message.success("余额支付成功")
							this.prepayModal = false
						}
						this.getUserInfo()

					}
				})
				.catch(err => {
					console.log(err, "err")
				})
		},
		// 
		onChange(e) {
			this.isChceked = e.target.checked
			e.target.checked ? this.disabled = false : this.disabled = true

		},
	}
}
</script>

<style lang="scss" scoped>
.prepay_modal {
	padding: 20px 0;

	.line {
		display: flex;
		margin-bottom: 30px;

		.label {}

		.value {
			display: flex;
			align-items: center;

			.way {
				border: 1px solid #ddd;
				padding: 10px 12px;
				border-radius: 10px;
				display: flex;
				align-items: center;
				margin-right: 15px;
				cursor: pointer;

				&.active {
					border-color: #23b571;
				}

				span {
					margin-left: 10px;
				}
			}
		}
	}

	.button {
		margin-top: 50px;
		display: flex;
		justify-content: center;
	}
}

.qrcode_modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 80px 0;

	.qrcode {
		width: 200px;
		height: 200px;
	}
}
</style>
